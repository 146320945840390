import React from "react"
import Layout from "../components/layout"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
	  <div id="thankyou-page">
              <div className="outer-container">
		  <div className="container-max-width">
		      <div className="inner-container center">
			  <div className="title-container">
			      <h1>Thank you for getting in touch…</h1>
			  </div>
			  <div className="content-container">
			      <h4 style={{marginTop : `0` }}>One of our team will be in touch with you shortly. Feel free to give us a buzz on <a href="tel:1300191950">1300 191 950</a> if you want to chat with someone right now.</h4>
			  </div>
		      </div>
		  </div>
              </div>
	  </div>
      </Layout>
    )
  }
}

export default IndexPage
